import React, { useId } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import {
  responsiveFlavors as responsiveFlavorsPropType,
  computed as ComputedPropType,
} from 'lib/CustomPropTypes';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { BREAKPOINT_SIZE_MAP } from 'lib/imageUtils';
import { getFormattedImgSrc } from 'lib/PictureUtils';

const DEFAULT_RESPONSE_FLAVORS = {
  s: AIMS_FLAVORS.FOCAL,
  m: AIMS_FLAVORS.FOCAL,
  l: AIMS_FLAVORS.FOCAL,
  xl: AIMS_FLAVORS.FOCAL,
};

/**
 * forces a preload by lifting a preload script to <Head>
 * this works by moving the resource load up in the event loop
 * and it works because once the initial SSR composition is done the preload script is fired off
 * this occurs prior to initial render
 *
 * the preload links are intended to improve our Largest Contentful Paint (LCP) score.
 * info: https://web.dev/preload-responsive-images/#preload-and-lesspicturegreater
 * @param {responsiveFlavors} responsiveFlavors
 * @param {string} flavor
 * @param {computed} computedValues
 * @param {string} url
 * @param {boolean} enableRetina
 * @returns {React.ReactElement}
 */
const PreloadImages = ({
  responsiveFlavors = DEFAULT_RESPONSE_FLAVORS,
  flavor = AIMS_FLAVORS.FOCAL,
  computedValues,
  url,
  enableRetina = false,
}) => {
  const sortedSizes = Object.keys(responsiveFlavors)
    .sort((a, b) => BREAKPOINT_SIZE_MAP[a] - BREAKPOINT_SIZE_MAP[b]);
  const multipleSizes = sortedSizes.length > 1;
  const id = useId();

  return (
    <>
      {sortedSizes.map((size, index) => {
        let media;
        const baseImgSrcObj = {
          size,
          responsiveFlavors,
          flavor,
          computedValues,
          url,
        };
        const nonRetinaSrc = getFormattedImgSrc(baseImgSrcObj);
        const retinaSrc = getFormattedImgSrc({
          ...baseImgSrcObj,
          scaleFactor: 2,
        });
        const srcSetValue = `${retinaSrc} 2x, ${nonRetinaSrc} 1x`;

        const key = `${id}-${size}-${index}`;

        // S Breakpoint
        if (size === 's') {
          const maxPixels = BREAKPOINT_SIZE_MAP[sortedSizes[1]];
          media = `(max-width: ${maxPixels}px)`;

          const props = {
            rel: 'preload',
            imagesrcset: enableRetina ? srcSetValue : nonRetinaSrc,
            as: 'image',
            media,
            fetchpriority: 'high',
          };


          return (
            <Head key={key}>
              <link {...props} />
            </Head>
          );
        }

        // Last index breakpoint
        if (multipleSizes && index === sortedSizes.length - 1) {
          const minPixels = BREAKPOINT_SIZE_MAP[sortedSizes[index]] + 0.1;
          media = `(min-width: ${minPixels}px)`;
        } else {
          const minPixels = BREAKPOINT_SIZE_MAP[sortedSizes[index]] + 0.1;
          const maxPixels = BREAKPOINT_SIZE_MAP[sortedSizes[index + 1]];
          media = `(min-width: ${minPixels}px) and (max-width: ${maxPixels}px)`;
        }

        const props = {
          rel: 'preload',
          imagesrcset: enableRetina ? srcSetValue : nonRetinaSrc,
          as: 'image',
          media,
          fetchpriority: 'high',
        };

        return (
          <Head key={key}>
            <link {...props} />
          </Head>
        );
      })}
    </>
  );
};

PreloadImages.propTypes = {
  flavor: PropTypes.string,
  responsiveFlavors: responsiveFlavorsPropType,
  computedValues: ComputedPropType,
  url: PropTypes.string,
  enableRetina: PropTypes.bool,
};

export { PreloadImages };
